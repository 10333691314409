<template>
  <div>
    <a-button @click="refresh" :loading="!isShow">Refresh</a-button>
    <iframe
      :src="iframeUrl"
      v-show="isShow"
      ref="frame"
      frameborder="0"
      width="100%"
      height="100%"
      style="min-height: 600px"
      allowtransparency
    ></iframe>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import { ref, onMounted, watch, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

export default {
  components: {},
  setup() {
    const route = useRoute()
    const store = useStore()

    const iframeUrl = ref({})
    const isShow = ref(true)
    const fetchData = () => {
      apiClient.get('/api/metabase/embed/' + route.params.id).then(response => {
        iframeUrl.value = response.data.url + '#titled=false&bordered=false'
      })
    }
    const refresh = () => {
      isShow.value = false
      fetchData()
      isShow.value = true
    }
    onMounted(() => {
      fetchData()
    })

    watch(
      () => route.params.id,
      newId => {
        refresh()
      },
    )

    return {
      iframeUrl,
      isShow,
      refresh,
    }
  },
}
</script>
